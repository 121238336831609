import * as React from "react"
import { Link, graphql } from 'gatsby'
import { linkResolver } from '../../utils/link_resolver'
import Seo from '../../components/seo'
import Footer from '../../components/footer'
import Header from '../../components/header'
import { GatsbyImage } from 'gatsby-plugin-image'

const MeasurementPage = ({data}) => {
  const entry = data.prismicMeasurementPage
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />
      <div className="w-full bg-black rounded-br-[133px]">
        <div className="flex-none md:flex justify-between">
          <div className="w-full md:w-6/12">
            <GatsbyImage className="w-full h-full block object-cover py-12 md:py-0" image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ?? ''} />
          </div>
          <div className="w-full md:w-6/12 px-16 py-16 md:py-36">
            <div className="leading-snug text-2xl text-white font-poppins font-semibold">
              <h2 className="mb-8">
                <span className="bg-white text-3xl text-orange px-3 py-1 font-bold leading-tight inline-block">{entry.data.tag1}</span>
              </h2>
              <p className="ml-3">{entry.data.heading}</p>
            </div>
            <Link to="/contact/" className="mt-8 inline-block bg-orange px-3 py-1 text-2xl text-white font-poppins font-medium">Contact us</Link>
          </div>
        </div>
      </div>
      <div className="w-full py-16 text-center font-poppins font-semibold">
        <div className="w-9/12 mx-auto max-w-screen-xl">
          <div className="grid grid-cols-1 sm:grid-cols-2 items-center gap-16">
            {entry.data.entries.map((entry, i) => (
              <div key={i}>
                <Link to={linkResolver(entry.page_link)}>
                  <div className={i%2 ? "font-poppins font-semibold text-diamond-80 text-3xl mb-6 leading-tight"
                    : "font-poppins font-semibold text-orange text-3xl mb-6 leading-tight"
                  }
                  >
                    <h2>{entry.heading1}</h2>
                  </div>
                  <GatsbyImage className="aspect-w-4 aspect-h-3 rounded-bl-[27px] rounded-tl-[27px] rounded-tr-[27px] w-full h-full block object-cover" image={entry.image1.gatsbyImageData} alt={entry.image1.alt ?? ''} />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full relative">
        <div className="absolute -inset-0">
          <GatsbyImage className="rounded-tl-[133px] w-full h-full block object-cover" image={entry.data.review_image.gatsbyImageData} alt={entry.data.review_image.alt ?? ''} />
        </div>
        <div className="absolute inset-0">
          <div className="flex items-end h-full">
            <div className="w-6/12">
              &nbsp;
            </div>
            <div className="w-6/12 h-full">
              <div className="w-full h-full block bg-gradient-to-l from-black via-black to-transparent">
                &nbsp;
              </div>
            </div>
          </div>
        </div>
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between relative">
            <div className="w-full md:w-6/12">
              &nbsp;
            </div>
            <div className="w-full md:w-6/12 pt-12 pb-12 md:pt-72 md:pb-24 text-right">
              <div className="font-poppins leading-tight text-xl md:text-2xl font-semibold">
                <h2 className="text-white mb-6"><em>“{entry.data.review_quote}”</em></h2>
                <p className="text-orange">- {entry.data.review_author}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicMeasurementPage {
    data {
      meta_description
      page_title
      tag1
      heading
      image {
        alt
        gatsbyImageData
      }
      entries {
        heading1
        image1 {
          alt
          gatsbyImageData
        }
        page_link {
          url
          uid
          type
          link_type
        }
      }
      review_image {
        alt
        gatsbyImageData
      }
      review_quote
      review_author
    }
  }
}
`

export default MeasurementPage
